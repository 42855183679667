import React, { useEffect, useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';


const Navbar= function() {

  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const flytext = document.getElementById("fly");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
       btn_icon(false);
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
          totop.classList.add("show");
          //flytext.classList.add("hide");
        } else {
          header.classList.remove("sticky");
          //flytext.classList.remove("hide");
          totop.classList.remove("show");
        }
      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);
    return(
        <nav className="navbar transition">
        <div className="container">
          <a className="navbar-brand" href="/">
              <img src="./img/new/logo.png" className="img-fluid d-block imginit navbar-logo" alt="logo"/>
              <span className="navbar-logo-text">Hipacreative art</span>
          </a>
          {/* Desktop menu Here */}
          <div className="dekstopmenu">
             <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link transition" to="/#hero-area">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" to="/#about">About me</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" to="/#gallery">Gallery</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" to="/#contact">Contact Me</Link>
              </li>
            </ul>
          </div>
          {/* Desktop menu Here */}

          {/* mobile menu here */}
          {showmenu &&
          <div className="mobilemenu" >
            <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
              <li className="nav-item">
                <Link className="nav-link" activeClass="active" smooth to="/#hero-area">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" activeClass="active" smooth to="/#about">About me</Link>
              </li>
                <li className="nav-item">
                    <Link className="nav-link" activeClass="active" smooth to="/#gallery">Gallery</Link>
                </li>
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" smooth to="/#contact">Contact Me</Link>
              </li>
            </ul>
          </div>
          }
          <button className="burgermenu" type="button" onClick={() => btn_icon(!showmenu)}>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          {/* mobile menu here */}
        </div>
      </nav>
    )
}

export default Navbar;