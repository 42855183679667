import React from 'react';

import "react-circular-progressbar/dist/styles.css";

import AOS from 'aos';
AOS.init();


const hero = () => {
	return(
		<div className="v-center">
		<div className="container">
			<a id="about"><span className="visually-hidden">about section</span></a>
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2 data-aos="fade-up"
                        data-aos-delay="0"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true">About Me</h2>
	                <div className="space-border"
	                	data-aos="fade-up"
                        data-aos-delay="20"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true"
	                	></div>
	            </div>
	            <div className="col-md-8 text-center m-auto"
	            	data-aos="fade-up"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
	            	>
	            	<p>I'm a self-taught artist, painter, web designer and front‑end developer. As a painter I focuse on depicting the less happy human feelings and dark arts. This gallery displays my work as a painter. Enjoy it :)</p>
	            </div>
			</div>
		</div>
		</div>
	);
}

export default hero;