import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery= function() {
    function galleryImageShow(id) {
        const modals = document.querySelectorAll(".LightboxGal");
        if (modals) {
            modals.forEach(modal => {
                const modalId = modal.dataset.id;
                if (modalId === id) {
                    modal.style.opacity = "1";
                    modal.style.visibility = "visible";
                }
            });
        }
    }
    function galleryImageHide(id) {
        const modals = document.querySelectorAll(".LightboxGal");
        if (modals) {
            modals.forEach(modal => {
                const modalId = modal.dataset.id;
                if (modalId === id) {
                    modal.style.opacity = "0";
                    modal.style.visibility = "hidden";
                }
            });
        }
    }

    /* content */
    return(
        <div className="container">
            <a id="gallery"><span className="visually-hidden">gallery section</span></a>
            <GlobalStyles/>
            <div className="row">
                    <div className="col-md-12 text-center">
                        <h2>Gallery</h2>
                        <div className="space-border"></div>
                    </div>
                </div>
                 <Masonry
                    className={"row my-gallery-class"} elementType={"div"}>
                    <div className="col-lg-4 image-element-class de_modal de_modal" onClick={() => galleryImageShow('62')}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Image #62</h3>
                                <h5 className="d-tag">Charcoal</h5>
                            </div>
                            <img src="./img/new/portfolio/preview/62.jpg" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('61')}
                        data-aos="fade-up"
                        data-aos-once="true"
                        > 
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Broken world</h3>
                                <h5 className="d-tag">Digital art</h5>
                            </div>
                            <img src="./img/new/portfolio/preview/61.jpg" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('60')}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Man-made darkness</h3>
                                <h5 className="d-tag">Digital art</h5>
                            </div>
                            <img src="./img/new/portfolio/preview/60.jpg" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('59')}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Endless nights</h3>
                                <h5 className="d-tag">Digital art</h5>
                            </div>
                            <img src="./img/new/portfolio/preview/59.jpg" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('58')}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Drown</h3>
                                <h5 className="d-tag">Acrylic</h5>
                            </div>
                            <img src="./img/new/portfolio/preview/58.jpg" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('57')}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Rain</h3>
                                <h5 className="d-tag">Acrylic</h5>
                            </div>
                            <img src="./img/new/portfolio/preview/57.jpg" alt="gallery"/>
                        </div>
                    </div>
                  </Masonry>
                <a className="full-gallery-button" href="/gallery">View full gallery</a>

                  {/* lightbox */}
                <div className="LightboxGal" data-id="62">
                    <div className="closeGal">
                        <button className="button-close" onClick={() => galleryImageHide('62')}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/62.jpg" alt="Image #62" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #62</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CjIOLHcIXXz/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "In a room full of art, I’d still stare at you..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div className="LightboxGal" data-id="61">
                    <div className="closeGal">
                        <button className="button-close" onClick={() => galleryImageHide('61')}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/new/portfolio/61.jpg" alt="Image #61" className="img-fluid"/>
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Broken world</h3>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/Chh8pMZo4Cf/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                    <blockquote>
                                        "How do we create beauty in a broken world? Such a big world and yet so empty..."
                                        <span>Jeidsoter</span>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="LightboxGal" data-id="60">
                    <div className="closeGal">
                        <button className="button-close" onClick={() => galleryImageHide('60')}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/new/portfolio/60.jpg" alt="Image #60" className="img-fluid"/>
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Man-made darkness</h3>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="#" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                    <blockquote>
                                        "In the man-made darkness, even the last bit of light will die."
                                        <span>Jeidsoter</span>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="LightboxGal" data-id="59">
                    <div className="closeGal">
                        <button className="button-close" onClick={() => galleryImageHide('59')}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/new/portfolio/59.jpg" alt="Image #59" className="img-fluid"/>
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Endless nights</h3>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CXo19LVo5S9/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                    <blockquote>
                                        "Nobody shall drive us away, I said. We're going to be happy here. We said it like a challenge to fate..."
                                        <span>Jeidsoter</span>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="LightboxGal" data-id="58">
                    <div className="closeGal">
                        <button className="button-close" onClick={() => galleryImageHide('58')}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/new/portfolio/58.jpg" alt="Image #58" className="img-fluid"/>
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Drown</h3>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CYhOn3VolEE/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                    <blockquote>
                                        "You don't drown by falling in the water, you drown by staying there."
                                        <span>Jeidsoter</span>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="LightboxGal" data-id="57">
                    <div className="closeGal">
                        <button className="button-close" onClick={() => galleryImageHide('57')}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/new/portfolio/57.jpg" alt="Image #57" className="img-fluid"/>
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Rain</h3>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="#" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                    <blockquote>
                                        "Your fate is the common fate of all into each life some rain must fall some days must be dark and dreary."
                                        <span>Jeidsoter</span>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
}

export default Gallery;