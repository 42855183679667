import Navbar from '../layout/Navbar';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import Fullgallery from '../section/fullGallery';
import ScrollToTopBtn from '../layout/ScrollToTop';
import { createGlobalStyle } from 'styled-components';
import Contact from "../section/contact";
import Gallery from "../section/gallery";

const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

function fullGallery() {
    return (
        <>
        <GlobalStyles/>
        <Preloader/>
        <div className="home">
            <header id="header-wrap">
                <Navbar />
            </header>

            {/* Gallery */}
            <section id="gallery">
                <Fullgallery />
            </section>

            <div className="float-text">
                <div className="de_social-icons">
                    <a href="https://www.facebook.com/Hipacreativeart" target="_blank" rel="noreferrer" className="buton"><i className="fa fa-facebook fa-lg"></i></a>
                    <a href="https://www.instagram.com/hipacreativeart/" target="_blank" rel="noreferrer" className="buton"><i className="fa fa-instagram fa-lg"></i></a>
                </div>
                <span>Follow Me</span>
            </div>
        </div>
        <ScrollToTopBtn />
        </>
    );
}

export default fullGallery;
