import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery= function() {
    function galleryImageShow(id) {
        const modals = document.querySelectorAll(".LightboxGal");
        if (modals) {
            modals.forEach(modal => {
                const modalId = modal.dataset.id;
                if (modalId === id) {
                    modal.style.opacity = "1";
                    modal.style.visibility = "visible";
                }
            });
        }
    }
    function galleryImageHide(id) {
        const modals = document.querySelectorAll(".LightboxGal");
        if (modals) {
            modals.forEach(modal => {
                const modalId = modal.dataset.id;
                if (modalId === id) {
                    modal.style.opacity = "0";
                    modal.style.visibility = "hidden";
                }
            });
        }
    }

    /* content */
    return(
        <div className="container">
            <GlobalStyles/>
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2>Gallery</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <Masonry
                className={"row my-gallery-class"} elementType={"div"}>
                <div className="col-lg-4 image-element-class de_modal de_modal" onClick={() => galleryImageShow('62')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #62</h3>
                            <h5 className="d-tag">Charcoal</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/62.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('61')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Broken world</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/61.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('60')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Man-made darkness</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/60.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('59')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Endless nights</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/59.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('58')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Drown</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/58.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('57')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Rain</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/57.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('56')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Storm</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/56.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('55')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>End of your journey</h3>
                            <h5 className="d-tag">Charcoal</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/55.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('54')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>End of the journey</h3>
                            <h5 className="d-tag">Charcoal</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/54.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('53')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Dreadful dream</h3>
                            <h5 className="d-tag">Charcoal</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/53.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('52')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #52</h3>
                            <h5 className="d-tag">Charcoal</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/52.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('51')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Embrace the darkness</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/51.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('50')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Brighter</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/50.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('49')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Your demons</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/49.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('48')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Hiding in the dark</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/48.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('47')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Thinking</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/47.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('46')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Day of the Dead art</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/46.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('45')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Waiting</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/45.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('44')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>So vulnerable</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/44.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('43')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Sit sown</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/43.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('42')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Immersed in depression</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/42.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('41')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Look</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/41.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('40')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Sad</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/40.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('39')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Never alone</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/39.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('38')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Tear</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/38.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('37')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Sunset</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/37.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('36')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Chains</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/36.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('35')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>My star</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/35.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('34')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Beneath the surface</h3>
                            <h5 className="d-tag">Charcoal</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/34.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('33')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Bad decision</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/33.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('32')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Lake of solitude</h3>
                            <h5 className="d-tag">Charcoal</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/32.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('31')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #31</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/31.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('30')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Nature</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/30.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('29')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Wild dreams</h3>
                            <h5 className="d-tag">Digital art</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/29.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('28')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Memories</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/28.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('27')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #27</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/27.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('26')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>In the veil of night</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/26.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('25')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #25</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/25.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('24')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #24</h3>
                            <h5 className="d-tag">Charcoal</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/24.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('23')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Overwhelming</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/23.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('22')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #22</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/22.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('21')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #21</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/21.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('20')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #20</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/20.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('19')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Music</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/19.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('18')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #18</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/18.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('17')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #17</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/17.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('16')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #16</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/16.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('15')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Wolverine</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/15.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('14')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Dark knight</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/14.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('13')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Ciri</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/13.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('12')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #12</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/12.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('11')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Moonlight</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/11.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('10')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Dark beauty</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/10.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('09')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #09</h3>
                            <h5 className="d-tag">Charcoal</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/09.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('08')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Rusalka</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/08.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('07')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #07</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/07.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('06')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Nightmares</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/06.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('05')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Lone wolf</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/05.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('04')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Fallen angel</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/04.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('03')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Just listen</h3>
                            <h5 className="d-tag">Pencil</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/03.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('02')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #02</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/02.jpg" alt="gallery"/>
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={() => galleryImageShow('01')}
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Image #01</h3>
                            <h5 className="d-tag">Acrylic</h5>
                        </div>
                        <img src="./img/new/portfolio/preview/01.jpg" alt="gallery"/>
                    </div>
                </div>
            </Masonry>

            {/* lightbox */}
            <div className="LightboxGal" data-id="62">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('62')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/62.jpg" alt="Image #62" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #62</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CjIOLHcIXXz/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "In a room full of art, I’d still stare at you..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="61">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('61')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/61.jpg" alt="Image #61" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Broken world</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/Chh8pMZo4Cf/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "How do we create beauty in a broken world? Such a big world and yet so empty..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="60">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('60')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/60.jpg" alt="Image #60" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Man-made darkness</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="#" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "In the man-made darkness, even the last bit of light will die."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="59">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('59')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/59.jpg" alt="Image #59" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Endless nights</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CXo19LVo5S9/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Nobody shall drive us away, I said. We're going to be happy here. We said it like a challenge to fate..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="58">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('58')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/58.jpg" alt="Image #58" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Drown</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CYhOn3VolEE/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "You don't drown by falling in the water, you drown by staying there."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="57">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('57')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/57.jpg" alt="Image #57" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Rain</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="#" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Your fate is the common fate of all into each life some rain must fall some days must be dark and dreary."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="56">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('56')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/56.jpg" alt="Image #56" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Storm</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CZZjXzZoCdB/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "A storm will always have a chaotic chorus of accompaniment..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="55">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('55')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/55.jpg" alt="Image #55" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>End of the journey</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CUm8q--otg4/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "The end of your journey is now in sight, and from the darkness you can now see light"
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="54">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('54')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/54.jpg" alt="Image #54" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>End of the journey</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CUI4G42oGMV/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "From a tiny kitten,<br />
                                    to the calling of a bird,<br />
                                    every creature has its cry,<br />
                                    with the hope to be heard...<br /><br />

                                    But there's no one to see,<br />
                                    there's no one to care<br />
                                    So you are standing<br />
                                    on the edge of despair..."<br />
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="53">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('53')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/53.jpg" alt="Image #53" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Dreadful dream</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CUDffWMIGn8/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "I can't find the words<br />
                                    That fill my confused head.<br />
                                    Can't find the reason to smile<br />
                                    Or get out of my stupid bed.<br /><br />
                                    Not sure what may be the answer.<br />
                                    Not sure if I'm really keen<br />
                                    To spend another day here,<br />
                                    Living this dreadful dream."<br />
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="52">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('52')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/52.jpg" alt="Image #52" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Dreadful dream</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CTxRmTqo7Wj/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    Charcoal on canvas quick experiment
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="51">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('51')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/51.jpg" alt="Image #51" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Dreadful dream</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CTRf6ZDoO-w/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Close your eyes and embrace the darkness"
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="50">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('50')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/50.jpg" alt="Image #50" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Dreadful dream</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CTAB2rTDKGP/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "The brighter the light, the darker the shadow.<br />
                                    The brighter the smile, the darker the mind."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="49">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('49')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/49.jpg" alt="Image #49" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Your demons</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CQhQh7fB8c2/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Don't let those demons in again..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="48">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('48')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/48.jpg" alt="Image #48" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Hiding in the dark</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CQhQh7fB8c2/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "I found myself in the dark, found her in the dark. I found light and truth in the darkness, hiding there where I couldn't see, right in front of me the whole time. And all I had to do was reach out and touch her."
                                    <span>Staci Hart</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="47">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('47')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/47.jpg" alt="Image #47" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Hiding in the dark</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CPvj-qWB6iu/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Standing alone in the dark and thinking.<br />
                                    Thinking, am I thinking?<br />
                                    No, my minds blank. Thoughts, memories, good or bad, have escaped me.<br />
                                    Need to feel so I'm searching.<br />
                                    Imprisoned in my brain, forever."<br />
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="46">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('46')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/46.jpg" alt="Image #46" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Day of the Dead art</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CPlxp_mhY3z/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "From my rotting body, flowers shall grow and I am in them and that is eternity."<br />
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="45">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('45')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/45.jpg" alt="Image #45" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Waiting</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CM42ZIkh_kw/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Waiting for something that never come..."<br />
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="44">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('44')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/44.jpg" alt="Image #44" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Waiting</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CMxFwQDhF_m/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "What happens when people open their hearts?"<br />
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="43">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('43')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/43.jpg" alt="Image #43" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Sit down</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CMP4ISeh0FW/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Sit down, you've got your life to waste"
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="42">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('42')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/42.jpg" alt="Image #42" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Sit down</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CMVRjhVBFQz/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "It is very hard to explain to people who have never known serious depression or anxiety the sheer continuous intensity of it. There is no off switch."
                                    <span>Matt Haig</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="41">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('41')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/41.jpg" alt="Image #41" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Sit down</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CMNiIc_hAwi/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "The simple lack of her is more to me than others’ presence."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="40">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('40')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/40.jpg" alt="Image #40" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Sit down</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CKJ4nnJh6nS/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "It's sad when someone you know becomes someone you knew..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="39">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('39')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/39.jpg" alt="Image #39" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Sit down</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJ3wBjbhrM5/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Be afraid. Be very afraid."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="38">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('38')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/38.jpg" alt="Image #38" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Tear</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJoZWgwh9b1/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "In tears was I born, and after tears I die."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="37">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('37')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/37.jpg" alt="Image #37" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Sunset</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJ9T7F0hK6u/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "It is almost impossible to watch a sunset and not dream"
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="36">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('36')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/36.jpg" alt="Image #36" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Chains</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CLxRUgrhUE-/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Free from chains. In mind, in soul, in life..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="35">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('35')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/35.jpg" alt="Image #35" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>My star</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CKPYi7VBwaB/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "What did you do, my star? Ships are burning right in the sky. Do you remember our kisses and how did you not say yes to me?<br />
                                    This drawing and song has a deep meaning for me and to complete it I had to wage an inner struggle with myself."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="34">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('34')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/34.jpg" alt="Image #34" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Beneath the surface</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJ57SanBmSl/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Deep within you, beneath the surface, he dwells and waits..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="33">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('33')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/33.jpg" alt="Image #34" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Bad decision</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CKFFdWJBq7F/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Don't make a bad decision..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="32">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('32')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/32.jpg" alt="Image #32" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Lake of solitude</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJ3iSZlhZ34/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "O solitude, where are the charms That sages have seen in thy face? Better dwell in the midst of alarms, Than reign in this horrible place. "
                                    <span>William Cowper</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="31">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('31')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/31.jpg" alt="Image #31" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #31</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJzSsjfhKyt/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="30">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('30')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/30.jpg" alt="Image #30" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Nature</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJd2DSYBg9R/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="29">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('29')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/29.jpg" alt="Image #29" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Wild dreams</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Digital art</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJ1U3fThV4Z/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "When wild dreams come to life..."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="28">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('28')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/28.jpg" alt="Image #28" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Memories</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJq1rbUBCOa/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Memories... they are not always nice and pleasant but also painfull. Regardless, it also belongs to life."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="27">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('27')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/27.jpg" alt="Image #27" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #27</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgrF5MxU6/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="26">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('26')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/26.jpg" alt="Image #26" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>In the veil of night</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEguEwM6ug/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "You are all the sun I need."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="25">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('25')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/25.jpg" alt="Image #25" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #25</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgoYbMeSg/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="24">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('24')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/24.jpg" alt="Image #24" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #24</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJl226pBwql/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="23">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('23')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/23.jpg" alt="Image #23" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Overwhelming</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJOGlGesIjj/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "When her look is overwhelming.."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="22">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('22')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/22.jpg" alt="Image #22" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #22</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJtDmunBws4/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="21">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('21')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/21.jpg" alt="Image #21" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #21</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgZ1ustda/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="20">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('20')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/20.jpg" alt="Image #20" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #20</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJZclv2h0JO/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="19">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('19')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/19.jpg" alt="Image #19" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Music</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJLj7TjMR1k/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Music is a cure for a troubled soul"
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="18">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('18')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/18.jpg" alt="Image #18" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #18</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEfsz8sEXS/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="17">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('17')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/17.jpg" alt="Image #17" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #17</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEfVyRsVXR/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="16">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('16')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/16.jpg" alt="Image #16" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #16</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJbQM61h0h3/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="15">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('15')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/15.jpg" alt="Image #15" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Wolverine</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgglZMJZF/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Don't be what they made you..."
                                    <span>Wolverine</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="14">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('14')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/14.jpg" alt="Image #14" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Dark knight</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgcyDMu4s/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "You either die a hero, or live long enough to see yourself become the villain"
                                    <span>Dark knight</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="13">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('13')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/13.jpg" alt="Image #13" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Ciri</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgMg4sIPK/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "You were always an unruly child..."
                                    <span>Vesemir</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="12">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('12')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/12.jpg" alt="Image #12" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #12</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEfenosesc/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="11">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('11')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/11.jpg" alt="Image #11" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Moonlight</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgj9XMBJh/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Beauty in the moonlight... "
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="10">
            <div className="closeGal">
                <button className="button-close" onClick={() => galleryImageHide('10')}></button>
            </div>
            <div className="v-center w-100">
                <div className="mainLightbox container">
                    <div className="row g-5">
                        <div className="col-lg-8">
                            <img src="./img/new/portfolio/10.jpg" alt="Image #10" className="img-fluid"/>
                        </div>

                        <div className="col-lg-4 de_project-info">
                            <h3>Dark beauty</h3>
                            <div className="de_project-details">
                                <div className="d-field">
                                    <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                </div>
                                <div className="d-field">
                                    <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                </div>
                                <div className="d-field">
                                    <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgI_rsxVP/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                </div>
                                <div className="d-field">
                                    <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                </div>
                            </div>
                            <div className="spacer-30"></div>
                            <blockquote>
                                "Even beauty can be dark"
                                <span>Jeidsoter</span>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="LightboxGal" data-id="09">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('09')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/09.jpg" alt="Image #09" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #09</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Charcoal</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgSzvsM9l/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="08">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('08')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/08.jpg" alt="Image #08" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Rusalka</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEgFQcsKbs/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "I was inspired by Russian stories about Rusalka. Water nymphs, which seduce travelers with beautiful singing and pull whoever succumbs to them into the depths."
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="07">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('07')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/07.jpg" alt="Image #07" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #07</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJHVBfdMI67/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="06">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('06')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/06.jpg" alt="Image #06" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Nightmares</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEfyLHMDw5/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "When nightmares come, stay quiet"
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="05">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('05')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/05.jpg" alt="Image #05" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Lone wolf</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEf0wtsAXv/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Do you sometimes feel like a lone wolf?"
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="04">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('04')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/04.jpg" alt="Image #04" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Fallen angel</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEfZZXsw6q/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="03">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('03')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/03.jpg" alt="Image #03" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Just listen</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Pencil</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEfmj9s5Rq/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "You don't have to talk much, just listen to your mate's hear"
                                    <span>Jeidsoter</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="02">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('02')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/02.jpg" alt="Image #02" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #02</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEf4EiMNXX/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LightboxGal" data-id="01">
                <div className="closeGal">
                    <button className="button-close" onClick={() => galleryImageHide('01')}></button>
                </div>
                <div className="v-center w-100">
                    <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <img src="./img/new/portfolio/01.jpg" alt="Image #01" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Image #01</h3>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa-solid fa-palette"></i>Type: <span>Acrylic</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-solid fa-calendar-days"></i>Year: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-square-instagram"></i>Instagram: <span><a href="https://www.instagram.com/p/CJEf9MLMHla/" target="_blank" rel="noreferrer">View on Instagram</a></span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa-brands fa-facebook"></i>Facebook: <span><a href="#" target="_blank" rel="noreferrer">View on Facebook</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gallery;